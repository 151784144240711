const env = process.env.NODE_ENV as 'development' | 'test' | 'production';

const BASE_URL = {
  // mock: "//9.134.116.6/api-doc/streamer/server_streamer/", // mock
  // development: "//9.134.116.6/api-doc/streamer/server_streamer/", // mock
  development: '//test-api.streamer.qq.com/streamer/',
  // development: "//test-api.streamer.qq.com/streamer",
  test: '//test-api.streamer.qq.com/streamer',
  production: '//api.streamer.qq.com/streamer/',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const baseURL = BASE_URL[env];

export { baseURL };
