// 引入axios
import axios from 'axios';
import { baseURL } from '@/config';

// 全局状态
const request = axios.create({
  headers: { 'Content-Type': 'application/json' },
  // timeout: 3000,
  baseURL,
  withCredentials: true,
});

// 响应拦截器
request.interceptors.response.use(
  (res) => {
    const { status, statusText } = res;
    if (status !== 200) {
      return Promise.reject(statusText);
    }
    const { ret, data } = res.data;
    if (ret && ret !== 0) {
      return Promise.reject(res.data);
    }
    return Promise.resolve(data);
  },
  (error) => Promise.reject(error),
);

export default request;
