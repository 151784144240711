import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// 引入后进行初始化
import Aegis from '@tencent/aegis-web-sdk';
const env = process.env.NODE_ENV;

if (env === 'production') {
  const aegis = new Aegis({
    id: 'udtHlRbgvbUTkQyPZB', // 项目ID，即上报id
    spa: true,
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    onError: false, // 关闭自动上报错误日志
  });
  window.onerror = function (errorMessage, scriptURI, lineNumber, columnNumber, errorObj) {
    if (typeof errorMessage === 'string' && errorMessage?.includes('window.bannerNight is not a function')) {
      return false;
    }
    aegis.error(`Error: ${errorMessage.toString()}\nStack: ${errorObj?.stack}`);
  };
}

createApp(App).use(router).mount('#app');
