
import { defineComponent, defineAsyncComponent, onMounted, ref } from 'vue';
import { getChannelUrl } from '@/api/index.ts';
import { downloadFile } from '@/utils';
import { useRouter } from 'vue-router';
// eslint-disable-next-line @typescript-eslint/no-require-imports
const Browser = require('../utils/ua.js');

export default defineComponent({
  name: 'Home',
  components: {
    Wechat: defineAsyncComponent(() => import('./wx/index.vue')),
    QQ: defineAsyncComponent(() => import('./qq/index.vue')),
    QQBrowser: defineAsyncComponent(() => import('./qqBrowser/index.vue')),
  },
  setup() {
    const info = new Browser();
    const { browser } = info;
    const components = ['Wechat', 'QQ', 'QQBrowser'];
    let name = browser;
    if (components.indexOf(browser) === -1) {
      name = 'default';
    }

    const router = useRouter();
    const query = router.currentRoute.value.query || {};
    const { cc = '' } = query;
    const url = ref('');
    const errMsg = ref('');
    function getUrl() {
      if (!cc) {
        errMsg.value = '链接缺失必要参数';
        return Promise.reject('链接缺失必要参数');
      }
      return getChannelUrl({ channel_code: cc })
        .then((res: any) => {
          if (res?.channel_url) {
            errMsg.value = ''; // 情况错误数据
            url.value = res?.channel_url;
            if (name === 'default') {
              handleDownload();
            }
            return Promise.resolve(res.channel_url);
          }
        })
        .catch((err: any) => {
          console.error(err);
          if (err && err.ret === 20220000) {
            errMsg.value = '链接已失效';
            return Promise.reject('链接已失效');
          }
          if (err && err.ret === 20220001) {
            errMsg.value = '链接不存在';
            return Promise.reject('链接不存在');
          }
        });
    }

    function handleDownload() {
      if (url.value) {
        downloadFile(url.value);
      } else {
        getUrl()
          .then(() => {
            downloadFile(url.value);
          })
          .catch((err: any) => {
            console.error(err);
          });
      }
    }

    onMounted(getUrl);

    return {
      name: ref(name),
      cc: ref(cc),
      url,
      errMsg,
      handleDownload,
    };
  },
});
