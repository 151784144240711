import request from '@/utils/request';
// import { AxiosPromise } from 'axios';
import { LocationQueryValue } from 'vue-router';

interface GetChannelUrlParams {
  channel_code: string | LocationQueryValue[] | null;
}

// interface GetChannelUrlRes {
//   channel_url: string;
// }

// 获取应用列表
export const getChannelUrl = (params: GetChannelUrlParams): any =>
  request({
    method: 'get',
    url: '/channel_url/get',
    params,
  });
