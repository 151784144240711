import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
// import qq from "../views/qq/index.vue";
// import qqBrowser from "../views/qqBrowser/index.vue";
// import wx from "../views/wx/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: "/qq",
  //   name: "qq",
  //   component: qq,
  // },
  // {
  //   path: "/qqBrowser",
  //   name: "qqBrowser",
  //   component: qqBrowser,
  // },
  // {
  //   path: "/wx",
  //   name: "wx",
  //   component: wx,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
